var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"col-12 col-md-8 col-lg-8 col-xl-6 mx-auto"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1)])])]):[_c('div',{staticClass:"col-12 col-md-8 col-lg-8 col-xl-6 mx-auto"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-sm-3"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"required",attrs:{"for":"login"}},[_vm._v(_vm._s(_vm.$t("form.name")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:[
                  {
                    'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
                  },
                  _vm.type === 'view' ? 'form-control-plaintext' : 'form-control',
                ],attrs:{"id":"login","placeholder":_vm.$t('form.name-placeholder'),"readonly":_vm.type === 'view'},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.name-req")))]):_vm._e()]):_vm._e()]),(_vm.type === 'edit' || _vm.type === 'view')?_c('div',{staticClass:"photo text-center"},[(!_vm.changePhoto)?_c('div',{staticClass:"my-3 mx-auto d-inline-block position-relative"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.photo}}),(_vm.type === 'edit')?_c('div',{staticClass:"change-photo",attrs:{"title":"Change photo"},on:{"click":function($event){_vm.changePhoto = true}}},[_c('i',{staticClass:"mdi mdi-pencil"})]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.type === 'create' || _vm.changePhoto)?_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"photo"}},[_vm._v(" "+_vm._s(_vm.$t('form.photo'))+" ")]),_c('b-form-file',{attrs:{"id":"photo"},model:{value:(_vm.form.photo),callback:function ($$v) {_vm.$set(_vm.form, "photo", $$v)},expression:"form.photo"}})],1):_vm._e(),_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"required",attrs:{"for":"description"}},[_vm._v(_vm._s(_vm.$t("form.descr")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"form-control",class:[
                  {
                    'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
                  },
                  _vm.type === 'view' ? 'form-control-plaintext' : 'form-control',
                ],attrs:{"id":"description","placeholder":_vm.type === 'view' && !_vm.form.description
                    ? ``
                    : _vm.$t('form.descr-placeholder'),"readonly":_vm.type === 'view'},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "description", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.description.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.description.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.descr-req")))]):_vm._e()]):_vm._e()]),(_vm.type === 'create')?_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('router-link',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"to":"/categories"}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"disabled":_vm.inprogress,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("category.add"))+" ")])],1)]):_vm._e(),(_vm.type === 'edit')?_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('router-link',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"to":"/categories"}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"disabled":_vm.inprogress,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")])],1)]):_vm._e(),(_vm.type === 'view')?_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('router-link',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"to":"/categories/"}},[_vm._v(" "+_vm._s(_vm.$t("btn.back"))+" ")]),_c('router-link',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"to":`/categories/edit/${_vm.$route.params.id}`}},[_vm._v(_vm._s(_vm.$t("btn.edit")))])],1)]):_vm._e()])],1)])]),(_vm.type !== 'create')?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('subcategoriesTable',{attrs:{"category":_vm.$route.params.id}})],1)])]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }