<template>
  <div>
    <div v-if="loading" class="col-12 col-md-8 col-lg-8 col-xl-6 mx-auto">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="col-12 col-md-8 col-lg-8 col-xl-6 mx-auto">
        <div class="card">
          <div class="card-body p-sm-3">
            <b-alert
              :variant="msg.type"
              dismissible
              class="mt-3"
              v-model="msg.has"
              :show="msg.text"
              >{{ msg.text }}</b-alert
            >

            <form @submit.prevent="handleSubmit" novalidate>
              <!-- name -->
              <div class="form-group mb-2">
                <label for="login" class="required">{{
                  $t("form.name")
                }}</label>
                <input
                  class="form-control"
                  v-model="form.name"
                  id="login"
                  :placeholder="$t('form.name-placeholder')"
                  :readonly="type === 'view'"
                  :class="[
                    {
                      'is-invalid': submitted && $v.form.name.$error,
                    },
                    type === 'view' ? 'form-control-plaintext' : 'form-control',
                  ]"
                />
                <div
                  v-if="submitted && $v.form.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.name.required">{{
                    $t("form.name-req")
                  }}</span>
                </div>
              </div>

              <!-- photo img -->
              <div
                v-if="type === 'edit' || type === 'view'"
                class="photo text-center"
              >
                <div
                  v-if="!changePhoto"
                  class="my-3 mx-auto d-inline-block position-relative"
                >
                  <img :src="photo" class="img-fluid" />
                  <div
                    v-if="type === 'edit'"
                    class="change-photo"
                    @click="changePhoto = true"
                    title="Change photo"
                  >
                    <i class="mdi mdi-pencil"></i>
                  </div>
                </div>
              </div>

              <!-- photo input -->
              <div
                v-if="type === 'create' || changePhoto"
                class="form-group mb-2"
              >
                <label for="photo">
                  {{ $t('form.photo') }}
                </label>
                <b-form-file id="photo" v-model="form.photo" />
              </div>

              <!-- description -->
              <div class="form-group mb-2">
                <label for="description" class="required">{{
                  $t("form.descr")
                }}</label>
                <input
                  class="form-control"
                  v-model="form.description"
                  id="description"
                  :placeholder="
                    type === 'view' && !form.description
                      ? ``
                      : $t('form.descr-placeholder')
                  "
                  :readonly="type === 'view'"
                  :class="[
                    {
                      'is-invalid': submitted && $v.form.description.$error,
                    },
                    type === 'view' ? 'form-control-plaintext' : 'form-control',
                  ]"
                />
                <div
                  v-if="submitted && $v.form.description.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.description.required">{{
                    $t("form.descr-req")
                  }}</span>
                </div>
              </div>

              <!-- CREATE button -->
              <div
                v-if="type === 'create'"
                class="mt-4 text-center text-lg-right"
              >
                <div class="form-group mb-0">
                  <router-link
                    to="/categories"
                    class="btn btn-warning mx-2 mb-2"
                  >
                    {{ $t("btn.cancel") }}
                  </router-link>
                  <button
                    :disabled="inprogress"
                    class="btn btn-primary mx-2 mb-2 mr-lg-0"
                    type="submit"
                  >
                    {{ $t("category.add") }}
                  </button>
                </div>
              </div>

              <!-- EDIT button -->
              <div
                v-if="type === 'edit'"
                class="mt-4 text-center text-lg-right"
              >
                <div class="form-group mb-0">
                  <router-link
                    to="/categories"
                    class="btn btn-warning mx-2 mb-2"
                  >
                    {{ $t("btn.cancel") }}
                  </router-link>
                  <button
                    :disabled="inprogress"
                    class="btn btn-primary mx-2 mb-2 mr-lg-0"
                    type="submit"
                  >
                    {{ $t("btn.save") }}
                  </button>
                </div>
              </div>

              <!-- VIEW button -->
              <div
                v-if="type === 'view'"
                class="mt-4 text-center text-lg-right"
              >
                <div class="form-group mb-0">
                  <router-link
                    to="/categories/"
                    class="btn btn-warning mx-2 mb-2"
                  >
                    {{ $t("btn.back") }}
                  </router-link>
                  <router-link
                    :to="`/categories/edit/${$route.params.id}`"
                    class="btn btn-primary mx-2 mb-2 mr-lg-0"
                    >{{ $t("btn.edit") }}</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div v-if="type !== 'create'" class="col-12">
        <div class="card">
          <div class="card-body">
            <subcategoriesTable :category="$route.params.id" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import subcategoriesTable from "@/views/pages/subcategories/table";

export default {
  props: {
    type: {
      type: String, // create, edit, view
      required: true,
    },
  },
  components: {
    subcategoriesTable,
  },
  data() {
    return {
      loading: true,
      submitted: false,
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      form: {
        name: "",
        description: "",
        photo: null,
      },
      formInit: null,
      img: "",
      changePhoto: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    formRules() {
      let rules = {};

      rules.name = {
        required,
      };

      rules.description = {
        required,
      };

      return rules;
    },
    photo() {
      return this.img || require("@/assets/images/nophoto.png");
    },
  },
  methods: {
    async loadData() {
      try {
        if (this.type === "edit" || this.type === "view") {
          this.getCategory();
        }

        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async getCategory() {
      this.loading = true;

      await axios
        .get(this.$urls.URL_CATEGORIES + "/" + this.$route.params.id)
        .then((response) => {
          console.log(
            `response, category id (${this.$route.params.id}): `,
            response
          );

          this.fillInitData(response);
          this.form = { ...this.formInit };
          this.loading = false;
        })
        .catch((error) => {
          console.log("getCategory Error", error);
          this.$router.push("/login");
        });
    },
    fillInitData(response) {
      this.img = response.data.photo;

      this.formInit = {
        name: response.data.name,
        description: response.data.description,
      };
    },
    async handleSubmit() {
      this.submitted = true;
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.inprogress = true;

        const formData = new FormData();

        // form
        formData.append("name", this.form.name);
        formData.append("description", this.form.description);

        if (this.form.photo) {
          formData.append("photo", this.form.photo);
        }

        // Display the key/value pairs ###debug
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + " :", pair[1]);
        // }

        if (this.type === "create") {
          try {
            const response = await axios.post(
              this.$urls.URL_CATEGORIES,
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );
            // console.log("response: ", response);

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("category.msg-add-success");

            this.inprogress = false;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message ||
              this.$t("category.msg-add-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          } finally {
            this.$scrollToTop();
          }
        }

        if (this.type === "edit") {
          formData.append("_method", "PATCH");

          try {
            const response = await axios.post(
              this.$urls.URL_CATEGORIES + "/" + this.$route.params.id,
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );
            // console.log("response: ", response);

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("category.msg-edit-success");

            this.inprogress = false;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message ||
              this.$t("category.msg-edit-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          } finally {
            this.$scrollToTop();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 180px;
}

.form-control-plaintext {
  background: transparent;
  border: none;
  color: #000;
}

.photo img {
  border: 2px solid #ccc;
  border-radius: 3px;
}

.change-photo {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 28px;
  opacity: 0;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 1;
  }

  .mdi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
